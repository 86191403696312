<template>
  <div class="evaluatee-carousel mb-3" v-if="selectedUsers.length > 1">
    <div class="current-evaluatee-label">
      <strong> Actualmente evaluando a: </strong>
    </div>
    <EvaluateeCarousel
      :current_users="selectedUsers"
      :actualTest="test"
      :sticky="false"
      @student_selcted="slotStudentSelected"
    ></EvaluateeCarousel>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "SelectedCoevaluatorRubric",
  components: {
    EvaluateeCarousel: () =>
      import(
        "@/components/new-evaluations/Evaluation/Evaluators/EvaluateeCarousel"
      ),
  },
  props: {
    rubric: {
      type: Object,
      required: true,
    },
    test: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    user_id: {
      type: Number,
      required: false,
    },
    is_running_test: {
      type: Boolean,
      default: false,
    },
    finished_test: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      //   institution: "getInstitution",
      evaluatee_rubrics: names.EVALUATEE_RUBRICS,
    }),
    evaluateeRubric() {
      if (
        this.user_id &&
        this.rubric &&
        (this.evaluatee_view || this.evaluator_view)
      )
        return this.evaluatee_rubrics.find(
          (x) => x.evaluatee == this.user_id && x.rubric == this.rubric.id
        );
      return null;
    },
    selectedUsers() {
      if (!this.evaluateeRubric) return [];
      if (this.evaluatee_view && this.is_running_test && !this.finished_test) {
        return this.evaluateeRubric.coevaluators;
      } else if (
        this.evaluator_view ||
        (this.evaluatee_view && this.finished_test)
      ) {
        return this.evaluateeRubric.coevaluating;
      }
      return [];
    },
  },
  methods: {
    slotStudentSelected(user_id) {
      this.$emit("student_selcted", user_id);
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped>
.evaluatee-carousel {
  background: var(--kl-menu-color);
  color: white;
  box-shadow: 0 1px 7px -4px var(--secondary-color);
  padding-top: 1.5em;
  padding-inline: 1em;
  transition: 0.2s;
  border-radius: 5px;
  display: flex;
}
.current-evaluatee-label {
  /* transform: translateY(-30%); */
  margin-left: 0.5em;
  margin-right: 1em;
  /* width: calc(40% + 10px); */
  width: 250px;
}
</style>

